<template>
  <ClientOnly>
    <div class="ccc-hotzone">
      <!-- 基础热区 -->
      <span
        v-for="(hotZone, i) in hotZones.standard"
        :id="hotZone.id"
        :key="hotZone.id"
        v-expose="getAnalysisData('2-22-1', handleAnalysisData(hotZone, i))"
        v-tap="getAnalysisData('2-22-2', handleAnalysisData(hotZone, i))"
        :style="getHotStyle(hotZone)"
      >
        <a
          :href="handleGetFullLink(hotZone, i)"
          class="j-hotZone block"
          role="link"
          tabindex="0"
          :aria-label="getAriaLabel(hotZone)"
          @click.prevent="clickHotZone(hotZone, i)"
        ></a>
      </span>

      <!-- 时间格式化组件 -->
      <span
        v-for="(hotZone) in hotZones.timeTransform"
        :id="hotZone.id"
        :key="hotZone.id"
        :style="getHotTimeComponentsStyle(hotZone)"
      >
        <a
          href="javascript:;"
          class="ccc-hotzone__tag"
          :style="getHotTimeBgColor(hotZone)"
        >
          {{ getDateTime(hotZone) }}
        </a>
      </span>

      <!-- 标签设置组件 -->
      <span
        v-for="(hotZone, i) in hotZones.label"
        :id="hotZone.id"
        :key="hotZone.id"
        v-expose="getAnalysisData('2-22-1', handleAnalysisData(hotZone, i))"
        v-tap="getAnalysisData('2-22-2', handleAnalysisData(hotZone, i))"
        :style="getLabelStyle(hotZone)"
        :class="[ 'ccc-label', {'ccc-label-l': isLeftLabel(hotZone)} ]"
      >
        <a
          :href="handleGetFullLink(hotZone, i)"
          role="link"
          tabindex="0"
          :aria-label="getAriaLabel(hotZone)"
          :class="[ 'ccc-label__href', { 'ccc-label__href-l': isLeftLabel(hotZone), 'ccc-label__href-s': showLabel }]" 
          @click.prevent="clickHotZone(hotZone, i, 'label')"
        >
          <div class="ccc-label__circle"></div>
          <div
            :class="['ccc-label__name', { 'ccc-label__style': isBlackStyle(hotZone) }]" 
            aria-hidden="true"
          >
            <div
              v-show="!isLeftLabel(hotZone)" 
              class="ccc-label__name-l"
            >
            </div>
            <div 
              :ref="'block' + i" 
              class="ccc-label__name__block"
            >
              <div class="ccc-label__name__box">
                <p
                  v-if="hotZone.config.showText" 
                  :class="['ccc-label__name__text', { 'ccc-label__name__pricel': hotZone.config.salePrice || hotZone.config.noJump }]"
                >
                  <span
                    v-if="hotZone.config.showImgNoPrice"
                    class="ccc-label__name__text__icon"
                  >
                    <Icon
                      name="sui_icon_more_right_16px_2"
                      :color="isBlackStyle(hotZone) ? '#000' : '#fff'"
                      :is-rotate="GB_cssRight"
                      class="more-right-icon"
                    />
                  </span>
                  <span>{{ hotZone.config.showText }}</span>
                </p>
              </div>

              <div 
                v-if="hotZone.config.salePrice" 
                class="ccc-label__name__price"
              >
                <span class="ccc-label__name__salePrice">{{ hotZone.config.salePrice }}</span>
                <Icon
                  v-show="hotZone.config.showImg"
                  name="sui_icon_more_right_16px_2"
                  size="0.41rem"
                  :color="isBlackStyle(hotZone) ? '#000' : '#fff'"
                  :is-rotate="GB_cssRight"
                  class="more-right-icon iconfont-s__img"
                />
              </div>
            </div>
            <div
              v-show="isLeftLabel(hotZone)" 
              class="ccc-label__name-r"
            ></div>
          </div>
        </a>
      </span>


      <!-- 仅标准热区需要弹窗 -->
      <s-dialog
        v-if="showDialog"
        class="ccc-hotzone__dialog"
        type="long"
        v-model:visible="showDialog"
        :show-close="true"
        :append-to-body="dialogAppendBody"
        @closed="() => { resetDialog() }"
      >
        <!-- 弹窗图片内容 -->
        <div
          v-if="popupImage.src"
          class="dialog-bg"
        >
          <img
            :src="transformImg({img: popupImage.src})"
            alt="弹窗图"
          />
        </div>
        
        <!-- 弹窗富文本内容 -->
        <ccc-text
          v-if="!!popupRichText.html"
          class="text-container"
          :html="popupRichText.html"
          :prop-data="propData"
          :scene-data="sceneData"
          :index="index"
          :cate-links="cateLinks"
          :background-color="popupRichText.backgroundColor"
        />

        <template #footer>
          <s-button
            class="dialog-btn"
            :width="6.7467"
            :type="['primary', 'H72PX']"
            @click="() => showDialog = false"
          >
            <span>{{ btnText }}</span>
          </s-button>
        </template>
      </s-dialog>
    </div>
  </ClientOnly>
</template>
  
<script>
import { expose, tap } from 'public/src/pages/common/analysis/directive'
import { defineComponent, nextTick } from 'vue'
import { CccLink, isInSpa } from 'public/src/pages/components/ccc/common/ccc-link.js'
import { timezoneFormatFromSite } from 'activity/common/timeFormat'
// import CccBaseImage from '../../base/BaseImg.vue'
import CccText from '../../base/BaseText.vue'
import { SButton } from '@shein-aidc/sui-button/mobile'
import { SDialog } from '@shein-aidc/sui-dialog/mobile'
import { transformImg } from '@shein/common-function'
import { getCccProductList } from 'public/src/services/api/ccc.js'
import { Icon } from '@shein-aidc/icon-vue3'
import { ClientOnly } from '@sheinfe/vue-client-only'
import { preFetchOrGetPageUrl } from 'public/src/services/lazyComponent/lazy-hydrate/pre-fetch/utils.js'

const { IS_RW = false, GB_cssRight = false } = gbCommonInfo || {}

export default defineComponent({
  name: 'CCCHotZone',
  directives: {
    expose,
    tap,
  },
  components: { CccText, SDialog, SButton, Icon, ClientOnly },
  props: {
    context: {
      type: Object,
      default: () => ({})
    },
    language: {
      type: Object,
      default: () => ({})
    },
    index: {
      type: Number,
      default: 0,
    },
    propData: {
      type: Object,
      default: () => ({}),
    },
    sceneData: {
      type: Object,
      default: () => ({}),
    },
    cateLinks: {
      type: Object,
      default: () => ({})
    },
    getAnalysisData: {
      type: Function,
      default: () => () => void 0
    },
    // 是否弹窗追加到body
    dialogAppendBody: {
      type: Boolean,
      default: false
    },
  },
  data() {
    const { componentName, component_id: compId } = this.propData
    const GB_cssRight = this.context?.GB_cssRight || false
    return {
      debugMode: false,
      IS_RW,
      cccLink: null,
      componentName,
      compId,
      hotZones: {
        label: [],
        standard: [],
        timeTransform: []
      },
      showDialog: false,
      btnText: this.language?.SHEIN_KEY_PWA_15146,
      // 弹窗图片数据结构
      popupImage: {
        src: '',
      },
      // 弹窗富文本数据结构
      popupRichText: {
        backgroundColor: 'inherit',
        html: '',
        raw: {}
      },
      hotZoneImgHeight: '100%',
      skus: [],
      showLabel: false,
      GB_cssRight,
    }
  },
  computed: {
    dateFormatOptions() {
      return {
        SiteUID: this.context?.SiteUID,
        lang: this.context?.lang,
        dateLangMap: this.context?.dateLangMap
      }
    }
  },
  created() {
    this.cccLink = new CccLink({ 
      sceneData: this.sceneData, 
      propData: this.propData 
    })
  },
  beforeMount() {
    const { NODE_SERVER_ENV } = gbCommonInfo
    this.isDev = ['debug', 'localhost'].includes(NODE_SERVER_ENV)
    this.hotZones.standard = this.propData?.hotZones?.standard || []
    this.hotZones.timeTransform =  this.propData?.hotZones?.timeTransform || []
    this.hotZones.label =  this.propData?.hotZones?.label || []
    this.initSkus()
    this.getLabelPrice()
  },
  methods: {
    /**
     * 初始化标签的sku,生成sku数组用于获取标签价格。
     */
    initSkus () {
      let skus = []
      this.hotZones.label?.forEach(i => {
        const { config = {} } = i
        config.labelType === 'goodsPrice' && config.sku.cateId && skus.push(config.sku.cateId)

        // 标签类型类型为文本 且 跳转到商品详情页
        if(config.hrefType.hrefType === 'sku' && config.hrefType.hrefTarget) { 
          skus.push(config.hrefType.hrefTarget)
        }

        // labelTextShow: customText \ goodsName \ noShow
        // labelType: text \ goodsPrice
        config.noJump = config.hrefType.hrefType === 'noJump' && config.labelType == 'text'
        config.showImg = config.hrefType.hrefType !== 'noJump' && config.textContent
        config.showImgNoPrice = config.hrefType.hrefType !== 'noJump' && config.labelType == 'text'
        config.showText = (config.labelType == 'text' || config.labelTextShow == 'customText' ) && config.textContent 
      })
      this.hotZones.label = [...this.hotZones.label]
      this.skus = [... new Set(skus)]
    },
    /**
     * 获取标签商品的价格数据，并塞入源数据渲染。
     */
    getLabelPrice() {
      if (!this.skus.length) {
        return this.caclLabelStyle()
      }
      getCccProductList({
        cateType: 'sku', 
        cateId: this.skus.join(','),
      }).then(resp => {
        const { code, data } = resp
        if (code != 0) return
        if (data.list && data.list.length) {
          this.hotZones.label.forEach(item => {
            const { config } = item
            const { labelType, sku, hrefType } = config

            // 将sku对应的商品价格，映射到对应的标签数据中进行渲染。
            if (hrefType.hrefType === 'sku' && hrefType.hrefTarget || labelType === 'goodsPrice' && sku.cateId) {
              data.list.forEach(i => {
                const { goods_sn, retailPrice, salePrice, goods_name, goods_id, productRelationID } = i
                if (labelType === 'goodsPrice' && goods_sn === sku.cateId) {
                  config.salePrice = salePrice.amountWithSymbol ? salePrice.amountWithSymbol : retailPrice.amountWithSymbol
                  config.goods_name = goods_name

                  config.showImg = config.hrefType.hrefType !== 'noJump' && (config.goods_name || config.textContent)
                  config.showText = config.labelTextShow == 'goodsName' && config.goods_name || config.labelTextShow == 'customText' && config.textContent
                }

                if (hrefType.hrefType === 'sku' && goods_sn === hrefType.hrefTarget) {
                  hrefType.goods_id = goods_id
                  hrefType.goods_sn = goods_sn
                  hrefType.productRelationID = productRelationID
                  hrefType.ext = { rec_mark: '-', other_ext_mark: '-' }
                  hrefType.salePrice = salePrice
                  hrefType.retailPrice = retailPrice
                }
              })
            }
          })
        }
        this.hotZones.label = [...this.hotZones.label]
        this.caclLabelStyle()
      })
    },
    isBlackStyle (hotZone) {
      // blackOnWhite 黑字白底 whiteOnBlack 白字黑底
      return hotZone.config && hotZone.config.labelStyle === 'blackOnWhite'
    },
    isLeftLabel (hotZone) {
      return hotZone.config && hotZone.config.labelDirection === 'left'
    },
    /**
     * 热区跳转
     */
    handleGetFullLink(hotZone, i) {
      let item = hotZone?.config?.hrefType || {}
      this.$store.commit('addProductDetailBfCache', {})
      return this.cccLink.getFullLink({
        item,
        cateLinks: this.cateLinks,
        compIndex: this.index,
        index: i,
        isBff: true,
        hotZoneParams: {
          hot_area: hotZone.name,
          tag_nm: hotZone.name
        } 
      })
    },

    jumpLink({ url, hrefType, routeType = 'push', scene = 'cccx' }) {
      if (url.indexOf('javascript:;') > -1) return
      if (isInSpa({ hrefType }) && hrefType !== 'activityNew') {
        if(window?._gb_app_) {
          this.$router[routeType](url)
        } else { // 兼容多页存在无_gb_app_实例case
          location.href = url
        }
      } else {
        location.href = preFetchOrGetPageUrl({ url, options: { isReturnUrl: true, scene } })
      }
    },

    /**
     * 热区定位
     */
    getHotStyle(hotZone) {
      let position = hotZone.position || {}
      return {
        position: 'absolute',
        cursor: 'pointer',
        border: this.isDev && this.debugMode ? '1px solid red' : 'none',
        top: position.y * 100 + '%',
        left: position.x * 100 + '%', 
        height: position.height * 100 + '%',
        width: position.width * 100 + '%',
      }
    },

    /**
     * 标签定位
     */
    getLabelStyle(hotZone) {
      let { position } = hotZone
      let data = {
        position: 'absolute',
        cursor: 'pointer',
        border: this.isDev && this.debugMode ? '1px solid red' : 'none',
        top: position.y * 100 + '%',
      }
      // 箭头向右时，和b端讨论后需要根据各种情况计算right
      if(this.isLeftLabel(hotZone)) {
        data.right = (1 - position.x) * 100  + '%'
      } else {
        data.left = position.x * 100 + '%'
      }
      return data
    },
    /**
     * 字体缩小为10px后ccc-label__name__block容器右侧会有空余
     * 缩小ccc-label__name__block容器宽度
     */
    caclLabelStyle () {
      if(!this.hotZones.label.length) return
      nextTick(() => {
        let block = this.$refs[`block0`][0]
        let maxWidth = this.getDomStyle(block, 'max-width')
        let maxHeight = this.getDomStyle(block.querySelector('.ccc-label__name__box'), 'max-height')
        // let minWidth = this.getDomStyle(block, 'min-width')
        let padding = this.getDomStyle(block, 'padding')
        this.hotZones.label.forEach(( { config: { showText, textContent, goods_name, salePrice } }, k) => {
          // 存在文字或者商品名称
          if(textContent || goods_name) {
            let dom = this.$refs[`block${k}`][0]
            let width = this.getDomStyle(dom, 'width')
            // 容器宽度小于最大宽度
            if(width < maxWidth) {
              // 计算 ccc-label__name__text 文字宽度
              let text = (width - 2 * padding) * 0.83333
              if(salePrice) {
                // 计算价格文字宽度
                let price = this.getDomStyle(dom.querySelector('.ccc-label__name__salePrice'), 'width') + this.getDomStyle(dom.querySelector('.iconfont-s__img'), 'width')
                if(text < price) return
              }
              // this.$refs[`block${k}`][0].style.width = text + 2 * padding < minWidth ? minWidth : text + 2 * padding + 'px'
              this.$refs[`block${k}`][0].style.width = Math.ceil(text + 2 * padding + 1) + 'px'
            }
          }
          // 存在文字且不存在价格
          if(showText && !salePrice) {
            let block = this.$refs[`block${k}`][0]
            let text = block.querySelector('.ccc-label__name__text')
            let scrollHeight = text.scrollHeight
            // 文本未超出3行
            if (scrollHeight <= Math.ceil(maxHeight) + 6) {
              text.classList.add('ccc-label__name__lines')
            }
          }
        })
        this.showLabel = true
      })
    },
    getDomStyle(dom, style) {
      return dom && parseFloat(getComputedStyle(dom)[style]) || 0
    },

    /**
     * 根据坐标从左至右,从上至下对基础热区排序 用于埋点上报hot_area_loc
     * wiki.pageId=910805674
     */
    // sortByCoord(arr) {
    //   let n = arr?.length
    //   if (!n) {
    //     return []
    //   }
    //   let [ ...result ] = arr
    //   let t = {}
    //   try {
    //     for(let i = 1; i < n; i++){
    //       for(let j = 0; j < n - i; j++){
    //         if(result[j].position.x == result[j + 1].position.x && result[j].position.y > result[j + 1].position.y){
    //           t = result[j]
    //           result[j] = result[j + 1]
    //           result[j + 1] = t
    //         } else if(result[j].position.x > result[j + 1].position.x){
    //           t = result[j]
    //           result[j] = result[j + 1]
    //           result[j + 1] = t
    //         }
    //       }
    //     }
    //   } catch(err) {
    //     result = arr
    //   }
    //   return result
    // },

    // 时间转化热区定位
    getHotTimeComponentsStyle (hotZone) {
      let config = hotZone.config || {}
      let fontColor = this.handleColor(config.fontColor)
      return {
        ...this.getHotStyle(hotZone),
        color: fontColor || 'black',
        fontSize: `${config.fontSize || '14'}px`
      }
    },

    getHotTimeBgColor(hotZone) {
      let config = hotZone.config || {}
      let { bgColorType = 'single' } = config
      let bgColorStart = this.handleColor(config.bgColorStart)
      let bgColorEnd = this.handleColor(config.bgColorEnd)
      const timeStyle = {
        background: 'none',
      }

      switch(bgColorType) {
        case 'gradation': timeStyle.background = `linear-gradient(to right, ${bgColorStart} 0%, ${bgColorEnd} 100%)`; break
        case 'single': timeStyle.background = bgColorStart; break
        case 'none':
          Object.assign(timeStyle, {
            transform: 'none',
            top: '0',
            left: '0',
            padding: '0',
            width: '100%',
            textAlign: 'center',
          })
          if (GB_cssRight) {
            Object.assign(timeStyle, {
              left: 'auto',
              right: '0',
            })
          }
          break
      }
      return timeStyle
    },
    
    // 如果有透明色 后台返回9位字符串 #ff123456 前两位是透明度， 需要解析成rgba #123456ff
    handleColor(str) {
      if(!str) return ''
      if(str.length === 9) {
        return str[0].concat(str.slice(3, 9), str.slice(1, 3))
      }
      return str
    },

    /**
     * 热区Ada label
     */
    getAriaLabel(hotzone) {
      return hotzone?.config?.hrefType?.ada || 'hotzone'
    },

    /**
     * 点击热区 跳转 or 弹窗
     */
    clickHotZone(hotZone, index, type) {
      this.resetDialog()
      let { hotZoneType, hrefType, popup } = hotZone?.config
      // 热点跳转类型
      if (+hotZoneType === 1 && hrefType || type) {
        const url = this.handleGetFullLink(hotZone, index)
        this.jumpLink({ url, hrefType: hrefType?.hrefType })
      } else if (+hotZoneType === 2 && popup) { // 热点弹窗
        let { popupType = 1, btnText } = popup
        this.btnText = btnText
        if (+popupType === 1) {
          // 图片
          this.popupImage = popup?.image || {}
          // 如果图片存在， 显示弹窗
          if (this.popupImage.src) {
            nextTick(() => {
              this.showDialog = true
            })
          }
        } else {
          // 富文本
          this.popupRichText = popup?.richText || {}
          if (this.popupRichText?.html) {
            nextTick(() => {
              this.showDialog = true
            })
          }
        }
      }
    },
   
    // 清除热区弹窗中的内容
    resetDialog() {
      this.popupImage = {
        src: ''
      }
      this.popupRichText = {
        backgroundColor: 'inherit',
        html: '',
        raw: {}
      }
    },

    /**
     * 时间格式化组件 时间格式化
     */
    getDateTime(hotZone) {
      const timeFormat = hotZone?.config?.timeFormat || 'MM/DD HH:mm'
      const start = hotZone?.config?.startTime
      const end = hotZone?.config?.endTime
      const startDate = start ? timezoneFormatFromSite(+start, 'shortDate', this.dateFormatOptions) : ''
      const startTime = start ? timezoneFormatFromSite(+start, 'onlyShortTime', this.dateFormatOptions) : ''
      const endDate = end ? timezoneFormatFromSite(+end, 'shortDate', this.dateFormatOptions) : ''
      const endTime = end ? timezoneFormatFromSite(+end, 'onlyShortTime', this.dateFormatOptions) : ''
      let result = ''
      switch(timeFormat) {
        case 'MM/DD HH:mm': 
          result = endDate ? `${startDate} ${startTime} - ${endDate} ${endTime}` : `${startDate} ${startTime}`; break
        case 'MM/DD': result = endDate ? `${startDate} - ${endDate}` : `${startDate}`; break
        case 'HH:mm': result = endDate ? `${startTime} - ${endTime}` : `${startTime}`
      }
      return result
    },

    /**
     * 埋点参数处理
     */
    handleAnalysisData(hotZone, index) {
      return { item: hotZone.config.hrefType, index, hotZoneParams: { hot_area: hotZone.name, hot_area_loc: index + 1, tag_nm: hotZone.name, useBffApi: true } }
    },

    transformImg,
  },
})
</script>
  
<style lang="less" scoped>
/* stylelint-disable declaration-no-important, selector-class-pattern, selector-nested-pattern */
@keyframes breathe {
  0% {
    transform: scale(0.5);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.5);
  }
}
.ccc-hotzone {
  .block {
    height: 100%;
    width: 100%;
    display: block;
  }
  &__dialog {
    cursor:default;
    .dialog-btn {
      padding: 0 .4267rem
    }
    .dialog-bg {
      width: 100%;
      img {
        width: 100%;
      }
    }
  }
  &__tag {
    white-space: nowrap;
    position: absolute;
    color: rgb(0, 0, 0);
    width: fit-content;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-decoration: none;
    line-height: 1.16;
    padding: .2133rem .8533rem;
    color: inherit;
  }

  .ccc-label {
    transform: translate(-0.2rem, -50%);
    &:after {
      content: '';
      display: block;
      clear: both;
    }
    &__href {
      display: flex;
      align-items: center;
      min-width: 1.76rem;
      overflow: visible;
      text-decoration: none;
      opacity: 0;
      pointer-events: none;
    }
    &__href-l {
      flex-direction: row-reverse;
    }
    &__href-s {
      opacity: 1; // 初始化完成后再显示
      pointer-events: auto;
    }
    &__name {
      float: right !important;
      display: flex;
      align-items: center;
      flex: 0 0 auto;
      &__box {
        display: flex;
        align-items: center;
        position: relative;
        line-height: 0.41rem;
        max-height: 1.18rem;
        width: 120%;
        margin-bottom: -0.16666%;
        transform: scale(.83333);
        transform-origin: left center;

        .more-right-icon {
          float: right;
          clear: both;
        }
      }

      &__block {
        max-width: 3.89rem;
        // min-width: 1.6rem;
        min-height: 0.64rem;
        padding: 0.16rem;
        background: rgba(0, 0, 0, 0.6);
        border-radius: 0.0533rem;
        font-size: 0.32rem;
        overflow-wrap: break-word;
        color: rgba(255, 255, 255, 1);
      }
      &__text {
        max-height: 1.18rem;
        overflow: hidden;
        word-break: break-all;
        text-overflow: ellipsis;
        text-align: left;
        position: relative;
        font-weight: 400;
        
        // span {
        //   word-break: break-all;
        //   overflow : hidden;
        //   text-align: left;
        //   text-overflow: ellipsis;
        //   display: -webkit-box;
        //   -webkit-line-clamp: 3;
        //   -webkit-box-orient: vertical;
        //   font-weight: 400;
        // }

        &__icon {
          float: right;
          clear: both;
          margin-left: .12rem;
          line-height: 0.28rem;
          position: relative;

          &::before {
            content: '...';
            position: absolute;
            left: .08rem;
            transform: translateX(-100%)
          }
        }

        &::before{
          content: '';
          height: calc(100% - 0.31rem);
          float: right;
        }
      }

      &__lines {
        // 未超过3行
        &::before{
          content: '';
          height: calc(100% - 0.33rem);
          float: right;
        }
        .ccc-label__name__text__icon {
          margin-left: .02rem;
          margin-right: .1rem;
          &::before {
            display: none;
          }
        }
      }

      &__pricel {
        overflow : hidden;
        text-align: left;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;

        &::before{
          display: none !important;
        }
      }

      &__price {
        font-weight: 700;
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: .41rem;

        .more-right-icon {
          font-size: 0.41rem;
          margin-left: .1067rem;
        }
      }

      &-l {
        width: 0Px;
        height: 0Px;
        border: 0.16rem solid transparent;
        border-right: 0.16rem solid rgba(0, 0, 0, 0.6);
        transform: translate(1%, 0);
      }
      &-r {
        width: 0Px;
        height: 0Px;
        border: 0.16rem solid transparent;
        border-left: 0.16rem solid rgba(0, 0, 0, 0.6);
        transform: translate(-1%, 0);
      }
    }
    &__style {
      .ccc-label__name__block {
        background: rgba(255, 255, 255, 1);
        color:rgba(0, 0, 0, 1)
      }
      .ccc-label__name-l {
        border-right: 0.16rem solid rgba(255, 255, 255, 1);
        transform: translate(2%, 0);
      }
      .ccc-label__name-r {
        border-left: 0.16rem solid rgba(255, 255, 255, 1);
        transform: translate(-2%, 0);
      }
    }

    &__circle {
      width: 0.4rem;
      height: 0.4rem;
      flex-shrink: 0;
      position: relative;

      &:before {
        content: '';
        display: block;
        width: 0.4rem;
        height: 0.4rem;
        border-radius: 50%;
        background: rgba(0, 0, 0, 0.3);
        position: absolute;
        top: 0;
        left: 0;
        animation: breathe 2s infinite;
        -webkit-animation: breathe 2s infinite;
      }

      &:after {
        content: '';
        display: block;
        width: 0.13rem;
        height: 0.13rem;
        border-radius: 50%;
        background: rgba(255, 255, 255, 1);

        position: absolute;
        left: 50%;
        top: 50%;
        z-index: 10;
        transform: translate(-50%, -50%);
      }
    }
    &__reverse {
      flex-direction: row-reverse;
    }
  }

  .ccc-label-l {
    transform: translate(0.2rem, -50%);
  }

  :deep(.sui-dialog__long) {
    width: 7.6rem;
    max-height: 53.5%;
  }
  :deep(.sui-dialog__body) {
    padding: 0 .4267rem;
  }
  :deep(.sui-dialog__closebtn) {
    padding: 0
  }
}
</style>
