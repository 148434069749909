<template>
  <div
    ref="superWideRef"
    class="super-wide-loop scroll-container"
    @touchstart="touchstartEvent"
    @touchmove="touchmoveEvent"
    @touchend="touchendEvent"
    @click="handleImgClick"
  >
    <div
      ref="scrollWrapRef"
      class="scroll-wrap"
      :style="[calcMaxWide, scollStyle]"
      @transitionend="transitionendEvent"
    >
      <div
        v-for="list in loopNum"
        :key="list"
        class="scroll-item"
      >
        <InvokeApp
          style="width: 100%; height: 100%"
          :style="[calcMaxWide]"
          :deeplink-source="item.hrefTarget"
          :is-direct-store="true"
          :is-deeplink-style-string="true"
          :is-invoke="item.hrefTarget?.indexOf?.('sheinlink') > -1"
        >
          <BaseImg
            v-if="item.image"
            v-tap="getAnalysisData('2-22-2', handleAnalysisData(item, index))"
            v-expose="getAnalysisData('2-22-1', handleAnalysisData(item, index))"
            :placeholder="{
              width: item.image.width,
              height: item.image.height
            }"
            fit="initial"
            :ratio="item.image.ratio"
            :img-src="item.image.src"
            :first-screen="true"
            :ada="item.ada"
            :alt="item.hrefTitle"
            :is-support-webp="propData.isSupportWebp"
            :img-design-width="calcWideValue < 375 ? 750 : calcWideValue * 2"
          />
        </InvokeApp>
        <HotZone
          v-if="showHotZone"
          :index="index"
          :context="context"
          :language="context.language"
          :prop-data="propData"
          :scene-data="sceneData"
          :cate-links="cateLinks"
          :get-analysis-data="getAnalysisData"
          :dialog-append-body="true"
          @click.stop
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, nextTick } from 'vue'
import BaseImg from '../../base/BaseImg.vue'
import InvokeApp from 'public/src/pages/components/ccc/base/InvokeApp.vue'
import HotZone from './HotZone.vue'

import { tap, expose } from 'public/src/pages/common/analysis/directive.js'
import { commonProps } from 'public/src/pages/components/ccc/common/common-props.js'
import mixins from 'public/src/pages/components/ccc/components/mixins/mixin.js'

export default defineComponent({
  name: 'SwiperWideLoop',
  components: {
    BaseImg,
    InvokeApp,
    HotZone
  },
  directives: { tap, expose },
  mixins: [mixins],
  emits: ['click'],
  props: {
    ...commonProps,
    item: {
      type: Object,
      default: () => ({})
    },
    showHotZone: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const { id: compId, props = {}, autoSlide = {} } = this.propData || {}
    const { metaData = {} } = props
    const { hrefConfig = {} } = metaData
    return {
      compId,
      autoSlide,
      hrefConfig, // 图片跳转配置
      isLeft: autoSlide?.autoSlideDirection === 'left', // 是否向左滑动
      loopNum: 2, // dom数量
      isCanLoop: true, // 是否可循环
      picWidth: 0, // 图片宽度
      currentOffset: 0, // 当前容器偏移位置
      timerIns: null, // 定时器实例
      speed: 0, // 过度效果时间
      startX: 0, // 触摸开始位置
      isContinue: false, // 拖动继续滚动标志
      pageList: [] // 获取节点
    }
  },
  computed: {
    calcMaxWide() {
      const w = this.item.image.width
      const h = this.item.image.height
      const maxHeight = 150 / 37.5
      return {
        width: Number(((maxHeight / h) * w).toFixed(2)) + 'rem',
        minWidth: '100%'
      }
    },
    calcWideValue() {
      const w = this.item?.image?.width
      const h = this.item?.image?.height
      const maxHeight = 150
      return Number(((maxHeight / h) * w).toFixed(2))
    },
    scollStyle() {
      return {
        transform: `translate3d(${this.currentOffset}px, 0, 0)`,
        transition: `transform ${this.speed}s linear`
      }
    }
  },
  mounted() {
    nextTick(() => {
      const cWidth = this.$refs.superWideRef?.getBoundingClientRect()?.width
      this.picWidth = this.$refs.scrollWrapRef?.getBoundingClientRect()?.width
      this.isCanLoop = this.picWidth > cWidth
      if (this.isCanLoop) {
        this.loopNum = 2
        this.pageList = this.$refs?.scrollWrapRef.childNodes
        this.startScroll()
      } else {
        this.loopNum = 1
      }
    })
  },
  activated() {
    nextTick(() => {
      const cWidth = this.$refs.superWideRef?.getBoundingClientRect()?.width
      this.picWidth = this.$refs.scrollWrapRef?.getBoundingClientRect()?.width
      this.isCanLoop = this.picWidth > cWidth
      if (this.isCanLoop) {
        this.loopNum = 2
        this.pageList = this.$refs?.scrollWrapRef.childNodes
        this.startScroll()
      } else {
        this.loopNum = 1
      }
    })
  },
  deactivated() {
    clearTimeout(this.timerIns)
    this.picWidth = 0
    this.currentOffset = 0
    this.speed = 0
  },
  beforeUnmount() {
    clearTimeout(this.timerIns)
  },
  methods: {
    startScroll() {
      // 用个宏任务先等dom归位再执行动画
      this.timerIns = setTimeout(() => {
        if (this.isLeft) {
          this.speed = this.autoSlide.autoSlideTime
          this.currentOffset = -this.picWidth
        } else {
          this.translate()
          this.speed = this.autoSlide.autoSlideTime
          this.currentOffset = this.picWidth
        }
        // 如果是拖动后继续滚动计算时间来维持匀速
        if (this.isContinue) {
          this.isContinue = false
          const remainTranslate = Math.abs(this.currentOffset) - Math.abs(this.getCurrentLeft())
          const remainTime = remainTranslate / Math.abs(this.currentOffset) * this.speed
          this.speed = Math.abs(remainTime)
        }
      }, 0)
    },
    // 滚动结束循环调用开始滚动事件
    transitionendEvent() {
      this.speed = 0
      this.currentOffset = 0
      this.startScroll()
    },
    touchstartEvent(e) {
      if (!this.isCanLoop) {
        return
      }
      // 触摸定位到获取当前left距离并且不再滚动
      this.speed = 0
      this.currentOffset = this.getCurrentLeft()

      this.startX = e.changedTouches[0].pageX
    },
    touchmoveEvent(e) {
      if (!this.isCanLoop) {
        return
      }
      const distance = e.changedTouches[0].pageX - this.startX
      this.startX = e.changedTouches[0].pageX
      this.currentOffset = this.currentOffset + distance

      this.translate()
    },
    touchendEvent() {
      if (!this.isCanLoop) {
        return
      }
      this.isContinue = true
      this.correctionPosition()
      this.startScroll()
    },
    // 拖动判断
    translate() {
      const _superWidth = this.$refs?.superWideRef.getBoundingClientRect().width
      const minOffset = -(this.pageList.length * this.picWidth - _superWidth)
      // 向左移动超过最大移动界限把第一张图移到后面
      if (this.currentOffset <= minOffset) {
        this.pageList[0].style = `transform: translate3d(${this.pageList.length * this.picWidth}px, 0, 0)`
      } else {
        this.pageList[0].removeAttribute('style')
      }
      // 向右拖动如果左边没有图了则把第二张图片移过去
      if (this.currentOffset >= 0) {
        this.pageList[this.pageList.length - 1].style = `transform: translate3d(-${this.pageList.length * this.picWidth}px, 0, 0)`
        // 向右滚动然后再向右拖动超过挪过去的第二张图还需把第一张图移到左边
        if (this.currentOffset >= this.picWidth) {
          this.pageList[0].style = `transform: translate3d(-${this.pageList.length * this.picWidth}px, 0, 0)`
        }
      } else {
        this.pageList[this.pageList.length - 1].removeAttribute('style')
      }
    },
    // 手动拖动后修正偏移位置
    correctionPosition() {
      this.pageList[0].removeAttribute('style')
      this.pageList[this.pageList.length - 1].removeAttribute('style')
      if (this.currentOffset >= 0) {
        this.currentOffset = this.currentOffset % this.picWidth
        if (this.isLeft) {
          this.currentOffset = this.currentOffset - this.picWidth
        }
        if (!this.isLeft) {
          this.translate()
        }
      }
      // 如果是向左移动的且移动超过第一张图则定位到第一张的相对位置
      if (this.currentOffset < 0) {
        this.currentOffset = this.currentOffset % -this.picWidth
        if (!this.isLeft) {
          this.currentOffset = this.currentOffset + this.picWidth
          this.translate()
        }
      }
      // console.log('修正位置', this.currentOffset)
    },
    // 获取当前滚动容器偏移量
    getCurrentLeft() {
      // const extraSpace = this.$refs?.superWidth?.getBoundingClientRect()?.x // 组件与页面侧间距
      const wrapperLeft = this.$refs?.scrollWrapRef?.getBoundingClientRect()?.x
      // console.log(extraSpace, wrapperLeft)
      return wrapperLeft
    },
    handleImgClick() {
      if (this.item.hrefTarget?.indexOf?.('sheinlink') > -1) return
      this.$emit(
        'click',
        {
          ...this.item,
          ...this.hrefConfig
        },
        0
      )
    },
    /**
     * 埋点参数处理
     */
    handleAnalysisData(item, index) {
      return {
        item: {
          ...item,
          ...this.hrefConfig
        },
        index,
        useBffApi: true // 使用bff接口提供的基础埋点参数
      }
    }
  },
})
</script>

<style lang="less" scoped>
@rem: 37.5rem;
[mir=rtl] .super-wide-loop {
  direction: ltr;
}
.super-wide-loop {
  width: 100%;
  overflow: hidden;
  .scroll-wrap {
    display: flex;
  }
  .scroll-item {
    width: 100%;
    position: relative;
  }
}
</style>
